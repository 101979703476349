import { useEffect, useState } from "react";

const Delegate = () => {
  const [data, setData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "load-delegates");
    form.append("q", 1);


    fetch("https://orders.murshidkids.com/api/", {
      method: "POST",
      body: form,
    })
    .then((res) => res.json())
    .then((res) => {
      setData(res);
      setPage(2);
    })
    .catch((err) => console.log(err));

  }, []);

  const handelSelectAll = () => {
    let checkboxes = document.getElementsByClassName("checkbox");
    for (let i = 0; i < checkboxes.length; i++) {
      if(toggle){
        checkboxes[i].checked = false;
      } else {
        checkboxes[i].checked = true;
      }
    }
    setToggle(!toggle);
  }

  const handelDelete = () => {
    let checkboxes = document.getElementsByClassName("checkbox");

    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "delete-item");
    form.append("q", 'delegates');
    
    for (let i = 0; i < checkboxes.length; i++) {
      if(checkboxes[i].checked){
        form.append("item_id[]", checkboxes[i].value);
      }
    }
    
    let r = window.confirm("هل أنت متأكد سيتم الحذف نهائياً؟");

    if(r){
      fetch("https://orders.murshidkids.com/api/", {
        method: "POST",
        body: form,
      })
      .then((res) => res.json())
      .then((res) => {
        window.location.reload();
      })
      //.catch((err) => console.log(err));
    }

  }

  
  const loadMore = () => {
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "load-delegates");
    form.append("q", page);

    fetch("https://orders.murshidkids.com/api/", {
      method: "POST",
      body: form,
    })
    .then((res) => res.json())
    .then((res) => {
      setData(dta => dta.concat(res));
      setPage(pg => pg+1);
    })
    //.catch((err) => console.log(err));
  }


  return (<>
    <h2>قائمة المندوبين <a href="/new-delegate" className="btn"><i className="fa fa-plus"></i> مندوب جديد</a></h2>

    <div className="inline-list col-3">
      <button className="inline-item" onClick={handelSelectAll}><i className="fa fa-list"></i> تحديد الكل</button>
      <button className="inline-item" onClick={handelDelete}><i className="fa fa-trash"></i> حذف</button>
    </div>
  
    <div className="list-view">
      {data && data.length > 0 && data.map((item, i) => (<label htmlFor={"checkbox"+item.id} className="list-item inline-list" key={"deleageno-"+i}>
        <span className="inline-item">
          <input type="checkbox" className="checkbox" name="checkbox[]" id={"checkbox"+item.id} value={item.id} />
        </span>
        <span className="inline-item col-3">{item.name}</span>
        <span className="inline-item col-3">{item.phone}</span>
      </label>))}
    </div>
    <br />
    {data.length > 4 && <button onClick={loadMore} className="center-div center">تحميل المزيد</button>}
  </>);
}

export default Delegate;