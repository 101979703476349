import { useEffect, useState } from "react";

const User = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [utype, setUType] = useState('admin');

    const handleSave = () => {
        let userItems = new FormData();
        userItems.append("key", window.localStorage.getItem("osys_token"));
        userItems.append("section", "insert-user");
        userItems.append("name", name);
        userItems.append("email", email);
        userItems.append("password", password);
        userItems.append("userType", utype);

        fetch("https://orders.murshidkids.com/api/", {
            method: "POST",
            body: userItems,
        })
        .then((res) => res.json())
        .then((res) => {
            window.location.href = "/users";
        })
        //.catch((err) => console.log(err));
    }

    return(<>
        <h1>مستخدم جديد</h1>
        <div className="card">
            <label className="col-3 inline-item" htmlFor="name">
                اسم المستخدم
                <input id="name" type="text" placeholder="اسم المستخدم" className="col-1" onChange={val => setName(val.target.value)} />
            </label>
            <label className="col-3 inline-item" htmlFor="email">
                البريد الإلكتروني
                <input id="email" type="email" placeholder="البريد الإلكتروني" className="col-1" onChange={val => setEmail(val.target.value)} />
            </label>
            <label className="col-3 inline-item" htmlFor="password">
                كلمة المرور
                <input id="password" type="password" placeholder="كلمة المرور" className="col-1" onChange={val => setPassword(val.target.value)} />
            </label>
            <label className="col-3 inline-item" htmlFor="utype">
                نوع المستخدم
                <select name="utype" id="utype" onChange={val => setUType(val.target.value)}>
                    <option value="admin">مشرف</option>
                    <option value="user">مستخدم</option>
                </select>
            </label>

            <br />
            <button onClick={handleSave}>إضافة</button>
        </div>
    </>)
}

export default User