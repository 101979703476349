import { useEffect, useState } from "react";

const Seller = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const handleSave = () => {
        let userItems = new FormData();
        userItems.append("key", window.localStorage.getItem("osys_token"));
        userItems.append("section", "insert-seller");
        userItems.append("name", name);
        userItems.append("phone", phone);

        fetch("https://orders.murshidkids.com/api/", {
            method: "POST",
            body: userItems,
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.status === "success") {
                window.location.href = "/sellers";
            } else {
                console.log(res.msg);
            }
        })
        //.catch((err) => console.log(err));
    }

    return(<>
        <h1>بائع جديد</h1>
        <div className="card">
            <label className="col-3 inline-item" htmlFor="name">
                اسم البائع
                <input id="name" type="text" placeholder="اسم البائع" className="col-1" onChange={val => setName(val.target.value)} />
            </label>
            <label className="col-3 inline-item" htmlFor="phone">
                رقم الهاتف
                <input id="phone" type="text" placeholder="رقم الهاتف" className="col-1" onChange={val => setPhone(val.target.value)} />
            </label>

            <br />
            <button onClick={handleSave}>إضافة</button>
        </div>
    </>)
}

export default Seller