import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const Order = () => {
    const { oid } = useParams();
    const [data, setData] = useState([]);
    const [method, setMethod] = useState("");
    const [order_state, setStatus] = useState("لم يستلم");
    const [order_payment, setPayment] = useState("انتظار الدفع");
    const [payment_method, setPaymentMethod] = useState("");
    const [payment_medium, setPaymentMedium] = useState("");
    const [payment_date, setPaymentDate] = useState("");
    const [delivery, setDelivery] = useState("");
    const [order_agent, setAgent] = useState("");
    const [order_agent_name, setAgentName] = useState("");
    const [seller, setSeller] = useState("");
    const [seller_name, setSellerName] = useState("");
    const [notesList, setNotes] = useState([]);
    const [updatesList, setUpdates] = useState([]);
    const [noteText, setNoteText] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [product, setProduct] = useState("");
    const [productName, setProductName] = useState("");
    
    const [name, setName] = useState("");
    const [order, setOrder] = useState("");
    const [qty, setQty] = useState(1);
    const [price, setPrice] = useState(0);
    const [amount, setAmount] = useState(0);
    const [total, setTotal] = useState(0);
    const [referrer, setReferrer] = useState("");
    const [phone, setPhone] = useState(0);
    const [mobile, setMobile] = useState(0);
    const [goverance, setGoverance] = useState("");
    const [stateName, setState] = useState("");
    const [city, setCity] = useState("");
    const [error_msg, setErrorMsg] = useState("");
    const [searchType, setSearchType] = useState('products');
  
    useEffect(() => {
        let form = new FormData();
        form.append("key", window.localStorage.getItem("osys_token"));
        form.append("section", "load-order");
        form.append("q", oid);

        let loadNotes = new FormData();
        loadNotes.append("key", window.localStorage.getItem("osys_token"));
        loadNotes.append("section", "load-notes");
        loadNotes.append("q", oid);
  
        let loadUpdates = new FormData();
        loadUpdates.append("key", window.localStorage.getItem("osys_token"));
        loadUpdates.append("section", "load-order-updates");
        loadUpdates.append("q", oid);

        fetch("https://orders.murshidkids.com/api/", {
            method: "POST",
            body: form,
        })
        .then((res) => res.json())
        .then((res) => {
            setData(res);
            if(res){
                res[0].method === 'null'?setMethod(""):setMethod(res[0].method);
                res[0].order_state === 'null'?setStatus("لم يستلم"):setStatus(res[0].order_state);
                res[0].order_payment === 'null'?setPayment("انتظار الدفع"):setPayment(res[0].order_payment);
                res[0].payment_method === 'null'?setPaymentMethod(""):setPaymentMethod(res[0].payment_method);
                res[0].payment_medium === 'null'?setPaymentMedium(""):setPaymentMedium(res[0].payment_medium);
                res[0].payment_date === 'null'?setPaymentDate(""):setPaymentDate(res[0].payment_date);
                res[0].delivery === 'null'?setDelivery(""):setDelivery(res[0].delivery);
                res[0].order_agent === 'null'?setAgent(""):setAgent(res[0].order_agent);
                res[0].order_agent_name === 'null'?setAgentName(""): setAgentName(res[0].order_agent_name);
                res[0].seller === 'null'?setSeller(""):setSeller(res[0].seller);
                res[0].product === 'null'?setProduct(""):setProduct(res[0].product);


                res[0].product === 'null'?setName(""):setName(res[0].name);
                res[0].product === 'null'?setOrder(""):setOrder(res[0].type);
                res[0].product === 'null'?setPrice(""):setPrice(res[0].price);
                res[0].product === 'null'?setAmount(""):setAmount(res[0].amount);
                res[0].product === 'null'?setMobile(""):setMobile(res[0].mobile);
                res[0].product === 'null'?setPhone(""):setPhone(res[0].phone);
                res[0].product === 'null'?setGoverance(""):setGoverance(res[0].goverance);
                res[0].product === 'null'?setState(""):setState(res[0].state);
                res[0].product === 'null'?setCity(""):setCity(res[0].city);
                res[0].product === 'null'?setReferrer(""):setReferrer(res[0].referrer);
                res[0].product === 'null'?setQty(1):setQty(res[0].qty);
                res[0].product === 'null'?setTotal(0):setTotal(res[0].total);
            }

        })
        .catch((err) => console.log(err))

        .then(() => {
            fetch("https://orders.murshidkids.com/api/", {
                method: "POST",
                body: loadNotes,
            })
            .then((jres) => jres.json())
            .then(resData => {
                setNotes(resData);
            })
            //.catch((notes_err) => console.log(notes_err))
            .then(() => {
                fetch("https://orders.murshidkids.com/api/", {
                    method: "POST",
                    body: loadUpdates,
                })
                .then((jres) => jres.json())
                .then(uresData => {
                    setUpdates(uresData);
                })
                //.catch((notes_err) => console.log(notes_err))

            })
            
        })
  
    }, []);

    useEffect(() =>{
        if(data.length > 0){
            let loadSeller = new FormData();
            loadSeller.append("key", window.localStorage.getItem("osys_token"));
            loadSeller.append("section", "load-seller");
            loadSeller.append("q", seller);

            
            fetch("https://orders.murshidkids.com/api/", {
                method: "POST",
                body: loadSeller,
            })
            .then((jres) => jres.json())
            .then(sellerData => {
                if(sellerData.status !== "error") {
                    setSellerName(sellerData[0].name);
                }
            })
            //.catch((notes_err) => console.log(notes_err))

            let loadProduct = new FormData();
            loadProduct.append("key", window.localStorage.getItem("osys_token"));
            loadProduct.append("section", "load-product");
            loadProduct.append("q", product);
            
            fetch("https://orders.murshidkids.com/api/", {
                method: "POST",
                body: loadProduct,
            })
            .then((pres) => pres.json())
            .then(productData => {
                console.log(productData);
                if(productData.status !== "error") {
                    setProductName(productData[0].name);
                }
            })
            //.catch((notes_err) => console.log(notes_err))

            let loadDelegate = new FormData();
            loadDelegate.append("key", window.localStorage.getItem("osys_token"));
            loadDelegate.append("section", "load-delegate");
            loadDelegate.append("q", order_agent);

            fetch("https://orders.murshidkids.com/api/", {
                method: "POST",
                body: loadDelegate,
            })
            .then((jres) => jres.json())
            .then(delegateData => {
                if(delegateData.status !== "error") {
                    setAgentName(delegateData[0].name);
                }
            })
            //.catch((notes_err) => console.log(notes_err))
        }

    }, [order_agent])

    const handleUpdate = () => {
        let form = new FormData();
        form.append("key", window.localStorage.getItem("osys_token"));
        form.append("section", "update-order");
        form.append("q", oid);
        form.append("method", method);
        form.append("order_state", order_state);
        form.append("order_payment", order_payment);
        form.append("payment_method", payment_method);
        form.append("payment_medium", payment_medium);
        form.append("payment_date", payment_date);
        form.append("delivery", delivery);
        form.append("order_agent", order_agent);
        form.append("user_id", window.localStorage.getItem("osys_user"));

        let updateForm = new FormData();
        updateForm.append("key", window.localStorage.getItem("osys_token"));
        updateForm.append("section", "insert-order-update");
        updateForm.append("q", oid);
        updateForm.append("user_id", window.localStorage.getItem("osys_user"));
        
        fetch("https://orders.murshidkids.com/api/", {
            method: "POST",
            body: form,
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.status === "success") {
                fetch("https://orders.murshidkids.com/api/", {
                    method: "POST",
                    body: updateForm,
                })
                .then((res) => res.json())
                .then((res) => {
                    window.location.reload();
                })
                //.catch((err) => console.log(err));
            } else {
                alert("حدث خطأ أثناء تحديث الطلب");
            }
        }
        )
        //.catch((err) => console.log(err));

    }

    const handleAddNote = () => {
        let form = new FormData();
        form.append("key", window.localStorage.getItem("osys_token"));
        form.append("section", "insert-note");
        form.append("note_user", window.localStorage.getItem("osys_user"));
        form.append("q", oid);
        form.append("note", noteText);

        fetch("https://orders.murshidkids.com/api/", {
            method: "POST",
            body: form,
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.status === "success") {
                window.location.reload();
            }
        })
        //.catch((err) => console.log(err));
    }

    const handleDelete = () => {
        let form = new FormData();
        form.append("key", window.localStorage.getItem("osys_token"));
        form.append("section", "delete-order");
        form.append("q", oid);

        let r = window.confirm("هل أنت متأكد سيتم الحذف نهائياً؟");

        if(r){
            fetch("https://orders.murshidkids.com/api/", {
                method: "POST",
                body: form,
            })
            .then((res) => res.json())
            .then((res) => {
                if (res.status === "success") {
                    window.location.href = '/orders';
                }
            })
            //.catch((err) => console.log(err));
        }
    }

    const handlePrint = () => {  
        let frm = document.getElementById('ipd').contentWindow;
        frm.focus();
        frm.print();
    }

    
    const handleSave = () => {
        let orderItems = new FormData();
        orderItems.append("key", window.localStorage.getItem("osys_token"));
        orderItems.append("section", "edit-order");
        orderItems.append("name", name);
        orderItems.append("order", order);
        orderItems.append("qty", qty);
        orderItems.append("price", price);
        orderItems.append("seller", seller);
        orderItems.append("amount", amount);
        orderItems.append("total", total);
        orderItems.append("referrer", referrer);
        orderItems.append("phone", phone);
        orderItems.append("mobile", mobile);
        orderItems.append("goverance", goverance);
        orderItems.append("state", stateName);
        orderItems.append("city", city);
        orderItems.append("product", product);
        orderItems.append("q", oid);

        fetch("https://orders.murshidkids.com/api/", {
            method: "POST",
            body: orderItems,
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.status === 'success') {
                window.location.reload()
            } else {
                setErrorMsg("هنالك خطأ اثناء الحفظ، الرجاء المحاولة لاحقا...")
            }
        })
        //.catch((err) => console.log("error: ", err));
    }

    useEffect(() => {
        let mywindow = document.getElementById('ipd').contentWindow.document;
        
        if(data.length > 0){
            mywindow.open();
            mywindow.write('<html dir="rtl"><head><title>'+data[0].token+'</title><meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/><meta name="viewport" content="width=device-width, initial-scale=1" /><style>@media print { body {font-size: 4em;} .footer,#non-printable {display: none !important;} #printable {display: block;} .inline-list .inline-item {display: inline-block; padding: 5px; margin: 2px; width: 45%;} #paper {width: 3508px; height: 2480px}}</style></head><body tyle="color: #000; padding: 5px">')
            
            mywindow.write('<div class="inline-list" id="paper">')
            mywindow.write('<div class="inline-item"><img src="../../logo.png" style="width: 25%; padding: 5px; margin: 5px;" /></div>')
            mywindow.write('<div class="inline-item">رقم: '+data[0].ono+' <br/> <i>تاريخ: '+data[0].reg_date+'</i></div><hr/>')
            mywindow.write('<h3>اسم المشتري: '+data[0].name+'</h3>');
            mywindow.write('<p>'+productName+'</p>');
            mywindow.write('<div class="inline-item">الطلب: '+data[0].type+'</div>')
            mywindow.write('<div class="inline-item">نوع السلعة: '+productName+'</div><br/>')
            mywindow.write('<div class="inline-item">الكمية: '+data[0].qty+'</div>')
            mywindow.write('<div class="inline-item">السعر: '+data[0].price+'</div><br/>')
            mywindow.write('<div class="inline-item">رسوم التوصيل: '+data[0].amount+'</div>')
            mywindow.write('<div class="inline-item">اجمالي المبلغ: '+data[0].total+'</div><br/>')
            mywindow.write('<div class="inline-item">اسم البائع: '+seller_name+'</div>')
            mywindow.write('<div class="inline-item">الطلب عن طريق: '+data[0].referrer+'</div><br/>')
            mywindow.write('<div class="inline-item">رقم هاتف الزبون: '+data[0].mobile+'</div>')
            mywindow.write('<div class="inline-item">رقم هاتف اخر: '+data[0].phone+'</div><br/>')
            mywindow.write('<div class="inline-item">المحافظة: '+data[0].goverance+'</div>')
            mywindow.write('<div class="inline-item">الولاية: '+data[0].state+'</div><br/>')
            mywindow.write('<div class="inline-item">المنطقة: '+data[0].city+'</div>')
            mywindow.write('<div class="inline-item">طريقة استلام الطلب: '+method+'</div><br/>')
            mywindow.write('<div class="inline-item">حالة الاستلام: '+order_state+'</div>')
            mywindow.write('<div class="inline-item">حالة الدفع: '+order_payment+'</div><br/>')
            mywindow.write('<div class="inline-item">طريقة الدفع: '+payment_method+'</div>')
            mywindow.write('<div class="inline-item">الدفع عن طريق: '+payment_medium+'</div><br>') 
            mywindow.write('<div class="inline-item">تاريخ الدفع او التحويل: '+payment_date+'</div>')
            mywindow.write('<div class="inline-item">التوصيل: '+delivery+'</div><br>') 
            mywindow.write('<p>اسم مندوب التوصيل: <br/> '+order_agent_name+'</p>')
            mywindow.write('</div>')
            mywindow.close()
        }

    }, [data])

    const handelSearch = val => {
        setAgentName(null)
        let form = new FormData();
        form.append("key", window.localStorage.getItem("osys_token"));
        form.append("section", "search-delegates");
        form.append("q", val.target.value);
    
        if(val.target.value.length > 2){
          fetch("https://orders.murshidkids.com/api/", {
            method: "POST",
            body: form,
          })
          .then((res) => res.json())
          .then((res) => {
            setSearchData(res);
          })
          //.catch((err) => console.log(err));
        }
    }

    const addAgent = (name, item) => {
        setAgentName(name);
        setAgent(item)
        setSearchData([]);
    }

    
    const handel2Search = (stype, val) => {
        setSearchType(stype)
        if(stype === 'sellers'){
            setSellerName(null);
        } else {
            setProductName(null);
        }
        let form = new FormData();
        form.append("key", window.localStorage.getItem("osys_token"));
        form.append("section", "search-"+stype);
        form.append("q", val.target.value);
    
        if(val.target.value.length > 2){
          fetch("https://orders.murshidkids.com/api/", {
            method: "POST",
            body: form,
          })
          .then((res) => res.json())
          .then((res) => {
            if(res.status !== 'error'){
                setSearchData(res);
            }
          })
          //.catch((err) => console.log(err));
        }
    }

    const addData = (name, item) => {
        if(searchType === 'sellers'){
            setSellerName(name);
            setSeller(item)
        } else {
            setProductName(name);
            setProduct(item)
        }
        setSearchData([]);
    }

    return (<>
        {data && data.map(item => (<div key={item.ono}><h1><i className="fa fa-file"></i>  {item.ono}</h1>
        <div className="card inline-list">
            <div id="info">
                <p><span className="inline-item">اسم المشتري:</span> <input type="text" className="inline-item col-3" name="name" id="name" value={name} onChange={val => setName(val.target.value)} /></p>

                <p className="inline-item col-3"><span className="inline-item">الطلب: </span> <input type="text" className="inline-item col-3" name="type" id="type" value={order} onChange={val => setOrder(val.target.value)} /></p>

                <p className="inline-item col-3"><span className="inline-item">نوع السلعة: </span> 
                    <input type="text" className="inline-item col-3" name="type" id="type" onChange={val => handel2Search('products', val)} value={productName} /> <br/>
                    {searchType === 'products' && searchData && searchData.length > 0 && searchData.map(item => (<div onClick={() => addData(item.name, item.id)} className="list-item"><i className="fa fa-user"></i> {item.name}</div>))}

                </p>
                
                <br />
                <p className="inline-item col-3"><span className="inline-item">الكمية: </span> <input type="text" className="inline-item col-3" name="qty" id="qty" value={qty} onChange={val => setQty(val.target.value)} /></p>

                <p className="inline-item col-3"><span className="inline-item">السعر: </span> <input type="text" className="inline-item col-3" name="price" id="price" value={price} onChange={val => setPrice(val.target.value)} /></p>

                <p className="inline-item col-3"><span className="inline-item">رسوم التوصيل: </span> <input type="text" className="inline-item col-3" name="amount" id="amount" value={amount} onChange={val => setAmount(val.target.value)} /></p>

                <p className="inline-item col-3"><span className="inline-item">اجمالي المبلغ: </span> <input type="text" className="inline-item col-3" name="total" id="total" value={total} onChange={val => setTotal(val.target.value)} /></p>

                <br />
                <p className="inline-item col-3"><span className="inline-item">اسم البائع: </span> 
                    <input type="text" className="inline-item col-3" name="seller_name" id="seller_name" value={seller_name} onChange={val => handel2Search('sellers', val)} />
                    <br/>
                    {searchType === 'sellers' && searchData && searchData.length > 0 && searchData.map(item => (<div onClick={() => addData(item.name, item.id)} className="list-item"><i className="fa fa-user"></i> {item.name}</div>))}
                </p>

                <p className="inline-item col-3"><span className="inline-item">الطلب عن طريق: </span> 
                    <select className="inline-item col-3" name="referrer" value={referrer} id="referrer" onChange={val => setReferrer(val.target.value)}>
                        <option value="الطلب عن طريق">الطلب عن طريق</option>
                        <option value="الأصدقاء أو الأقارب">الأصدقاء أو الأقارب</option>
                        <option value="السوشيال ميديا"> السوشيال ميديا</option>
                        <option value="البائع مباشرة">البائع مباشرة</option>
                        <option value="أخرى">أخرى</option>
                    </select>
                </p>

                <br />

                <p className="inline-item col-3"><span className="inline-item">رقم هاتف الزبون: </span> <input type="text" className="inline-item col-3" name="mobile" id="mobile" value={mobile} onChange={val => setMobile(val.target.value)} /></p>

                <p className="inline-item col-3"><span className="inline-item">رقم هاتف اخر: </span> <input type="text" className="inline-item col-3" name="phone" id="phone" value={phone} onChange={val => setPhone(val.target.value)} /></p>

                <br />
                <p className="inline-item col-3"><span className="inline-item">المحافظة: </span>
                    <select className="inline-item col-3" value={goverance} name="goverance" id="goverance" onChange={val => setGoverance(val.target.value)}>
                        <option value="محافظة مسقط">محافظة مسقط</option>
                        <option value="محافظة مسندم">محافظة مسندم</option>
                        <option value="محافظة البريمي">محافظة البريمي</option>
                        <option value="محافظة الباطنة">محافظة الباطنة</option>
                        <option value="محافظة الظاهرة ">محافظة الظاهرة </option>
                        <option value="محافظة الداخلية">محافظة الداخلية</option>
                        <option value="محافظة الشرقية">محافظة الشرقية</option>
                        <option value="محافظة الوسطى">محافظة الوسطى</option>
                        <option value="محافظة ظفار">محافظة ظفار</option>
                    </select>
                </p>

                <p className="inline-item col-3"><span className="inline-item">الولاية: </span> <input type="text" className="inline-item col-3" name="state" id="state" value={stateName} onChange={val => setState(val.target.value)} /></p>

                <p className="inline-item col-2"><span className="inline-item">المنطقة: </span> <input type="text" className="inline-item col-2" name="city" id="city" value={city} onChange={val => setCity(val.target.value)} /></p>
            </div>
            <div className="inline-list">
                <button className="inline-item mrgn" onClick={handleSave}><i className="fa fa-edit"></i> تعديل</button>
                <button className="inline-item mrgn" onClick={handlePrint}><i className="fa fa-print"></i> طباعة</button>
                <button className="inline-item mrgn" onClick={handleDelete}><i className="fa fa-trash"></i> حذف</button>

            </div>
        </div>
        

        <h2><i className="fa fa-user"></i> معالجة الطلب</h2>

        <div className="card inline-list">
            <div id="info2">
            <label htmlFor="method">
                طريقة استلام الطلب 
                <input type="text" name="method" id="method" placeholder="طريقة استلام الطلب" className="col-1" value={method} onChange={val => setMethod(val.target.value)} />
            </label>
            <br />
            <label className="col-3 inline-item" htmlFor="order_state">
                حالة الاستلام 
                <select name="order_state" id="order_state" value={order_state} onChange={val => setStatus(val.target.value)}>
                    <option value="استلم">استلم</option>
                    <option value="لم يستلم">لم يستلم</option>
                    <option value="جاري التوصيل">جاري التوصيل</option>
                </select>
            </label>
            
            <label className="col-3 inline-item" htmlFor="order_payment">
                حالة الدفع  
                <select name="order_payment" id="order_payment" value={order_payment} onChange={val => setPayment(val.target.value)}>
                    <option value="دفع">دفع</option>
                    <option value="انتظار الدفع">انتظار الدفع</option>
                </select>
            </label>
            
            <label className="col-3 inline-item" htmlFor="payment_method" >
                طريقة الدفع   
                <select name="payment_method" id="payment_method" value={payment_method} onChange={val => setPaymentMethod(val.target.value)}>
                    <option value="نقدا">نقدا</option>
                    <option value="تحويل بنك مسقط">تحويل بنك مسقط</option>
                    <option value="تحويل البنك الوطني">تحويل البنك الوطني</option>
                    <option value="شيك">شيك</option>
                </select>
            </label>
            
            <label className="col-3 inline-item" htmlFor="payment_medium">
                الدفع عن طريق    
                <select name="payment_medium" id="payment_medium" value={payment_medium} onChange={val => setPaymentMedium(val.target.value)}>
                    <option value="النقد">النقد</option>
                    <option value="بنك">بنك</option>
                </select>
            </label>

            <label htmlFor="payment_date" className="col-3 inline-item">
                تاريخ الدفع او التحويل 
                <input type="date" name="payment_date" id="payment_date" className="col-1" value={payment_date} onChange={val => setPaymentDate(val.target.value)} />
            </label>
            
            <label className="col-3 inline-item" htmlFor="delivery">
                التوصيل     
                <select name="delivery" id="delivery" value={delivery} onChange={val => setDelivery(val.target.value)}>
                    <option value="شامل توصيل">شامل توصيل</option>
                    <option value="غير شامل توصيل">غير شامل توصيل</option>
                </select>
            </label>

            <label htmlFor="order_agent" className="col-3 inline-item">
                اسم مندوب التوصيل  
                <input type="text" name="order_agent" id="order_agent" className="col-1" value={order_agent_name} onChange={handelSearch} />
            </label>
                        
            <div className="list-view inline-item col-3">
                <small>قم بكاتبة اسم المندوب ليتم الاكمال التلقائي من قائمة المندوبين...</small>
                {searchData && searchData.length > 0 && searchData.map(item => (<div onClick={() => addAgent(item.name, item.id)} className="list-item"><i className="fa fa-user"></i> {item.name}</div>))}
            </div>

            </div>
            <button onClick={handleUpdate}><i className="fa fa-save"></i> تحديث</button>
        </div>

        </div>))}

        <h2><i className="fa fa-edit"></i> الملاحظات</h2>

        <div className="card list-view2">
            <label htmlFor="note">
                إضافة ملاحظة 
                <textarea name="note" id="note" cols="50" rows="5" onChange={val => setNoteText(val.target.value)}></textarea>
            </label>

            <button onClick={handleAddNote}><i className="fa fa-plus"></i> إضافة</button>

            <hr />

            {notesList && notesList.length > 0 && notesList.map(note_item => (<div className="list-item" key={note_item.id}>
                <p>{note_item.note}</p>
                <small>{note_item.note_date} - من قبل {note_item.user_name}</small>
                <hr />
            </div>))}
        </div>

        <h2><i className="fa fa-clock"></i> سجل التعديل</h2>

        <div className="list-view">
            {data && data.length > 0 && <p className="list-item">{data[0].reg_date} - تم إنشاء الطلب من قبل {data[0].user_name}</p>}
            {updatesList && updatesList.length > 0 && updatesList.map(update_item => (<p className="list-item" key={update_item.id}>{update_item.update_date} - تم تعديل الطلب من قبل {update_item.user_name}</p>))}
        </div>
        <iframe id="ipd" style={{width: '0px', height: '0px'}}></iframe>
    </>)
}

export default Order;