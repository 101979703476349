import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import New from "./New";
import Order from "./Order";
import Orders from "./Orders";
import Users from "./Users";
import User from "./User";
import Reports from "./Reports";
import Delegate from "./Delegate";
import Delegates from "./Delegates";
import Sellers from "./Sellers";
import Seller from "./Seller";
import Products from "./Products";
import Product from "./Product";
import Systembackups from "./Systembackups";


const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("user");
  const [error, setError] = useState("");
  const [tokenkey, setToken] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "check-user");

    fetch("https://orders.murshidkids.com/api/", {
      method: "POST",
      body: form,
    })
    .then((res) => res.json())
    .then((res) => {
      setLoading(false)
      if(res.status === "success"){
        setLoggedIn(true)
      } else {
        setLoggedIn(false);
      }
    })
    .catch((err) => {
      setLoggedIn(false)
      setLoading(false)
    })
    
    setUserType(window.localStorage.getItem("osys_userType"));
  }, []);

  const handelLogin = () => {
    setError('');
    let form = new FormData();
    //form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "login");
    form.append("email", email);
    form.append("password", password);

    if(email === "" || password === ""){
      setError("يرجى إدخال البيانات المطلوبة");
    } else {
      fetch("https://orders.murshidkids.com/api/", {
        method: "POST",
        body: form,
      })
      .then((res) => res.json())
      .then((res) => {
        if(res.status === "success"){
          setLoggedIn(true);
          localStorage.setItem("osys_token", res.token);
          localStorage.setItem("osys_user", res.user);
          localStorage.setItem("osys_userType", res.usertType);
          window.location.reload();
        } else {
          setLoggedIn(false);
          setError('خطأ في كلمة المرور أو البريد الإلكتروني');
        }
      })
      .catch((err) => {
        setLoggedIn(false);
        setError('خطأ في الخادم');
      });
    }
  }

  const handelLogout = () => {
    setLoggedIn(false);
    localStorage.removeItem("osys_token");
    localStorage.removeItem("osys_user");
  }

  const toggleMenu = () => {
    document.querySelector("#menu").classList.toggle("mobile-menu");
  }

  return (<>
    {loading && <div className="loading">
        <img src={require('./logo.png')} />
    </div>}
    <div className="contanier">
      {!loggedIn && <div className="card col-2 mrgn inline-list center-div">
        <div className="inline-item col-3">
          <img src={require('./logo.png')} className="col-1" />
        </div>
        <div className="inline-item col-3">
          <h2>تسجيل الدخول</h2>
          <label htmlFor="email">البريد الإلكتروني</label>
          <input type="email" id="email" className="col-1" onChange={val => setEmail(val.target.value)}/>
          <label htmlFor="password">كلمة المرور</label>
          <input type="password" id="password" className="col-1" onChange={val => setPassword(val.target.value)} />
          <p className="error">{error}</p>
          <button onClick={handelLogin}>دخول</button>
        </div>

      </div>}

      {loggedIn && <><nav>
        <img src={require('./logo.png')} className="logo" />
        <button className="btn mobile-btn" onClick={toggleMenu}>
          <h1>
            <i className="fa fa-bars"></i> القائمة الرئيسية
          </h1>
        </button>
        <div id="menu" className="mobile-menu">
          <a href="/"><i className="fa fa-home"></i> الرئيسية</a>
          <a href="/orders"><i className="fa fa-list"></i> قائمة الطلبات</a>
          <a href="/sellers"><i className="fa fa-people-group"></i> قائمة البائعين</a>
          <a href="/delegates"><i className="fa fa-people-group"></i> قائمة المندوبين</a>
          <a href="/products"><i className="fa fa-tags"></i> قائمة انواع السلع</a>
          {userType === 'admin' && <><a href="/reports"><i className="fa fa-file"></i> التقارير</a>
          <a href="/users"><i className="fa fa-users"></i> مستخدمين النظام</a>
          <a href="/system-backups"><i className="fa fa-database"></i> النسخ الإحطياطية</a></>}
          <button onClick={handelLogout}><i className="fa fa-arrow-right-from-bracket"></i> تسجيل الخروج</button>
          {/* <div className="pl"></div> */}
        </div>
      </nav>

      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/new" element={<New />} />
          <Route path="/order/:oid" element={<Order />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/users" element={<Users />} />
          <Route path="/new-user" element={<User />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/delegates" element={<Delegates />} />
          <Route path="/new-delegate" element={<Delegate />} />
          <Route path="/sellers" element={<Sellers />} />
          <Route path="/new-seller" element={<Seller />} />
          <Route path="/products" element={<Products />} />
          <Route path="/new-product" element={<Product />} />
          <Route path="/system-backups" element={<Systembackups />} />
        </Routes> 
      </main></>}


    </div>
    <div className="pl"></div>
    <div className="footer">
      <p className="copyright">جميع الحقوق محفوظة لمركز تكنولوجيا الصحافة والنشر والإعلان</p>
    </div>
  </>);
}

export default App;
