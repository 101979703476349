import { useEffect, useState } from "react";

const Product = () => {
    const [name, setName] = useState('');

    const handleSave = () => {
        let userItems = new FormData();
        userItems.append("key", window.localStorage.getItem("osys_token"));
        userItems.append("section", "insert-product");
        userItems.append("name", name);

        fetch("https://orders.murshidkids.com/api/", {
            method: "POST",
            body: userItems,
        })
        .then((res) => res.json())
        .then((res) => {
            if(res.status === 'success'){
                window.location.href = "/products";
            } else {
                console.log(res);
            }
        })
        //.catch((err) => console.log(err));
    }

    return(<>
        <h1>نوع جديد</h1>
        <div className="card">
            <label className="col-3 inline-item" htmlFor="name">
                إسم السلعة
                <input id="name" type="text" placeholder="اسم السلعة" className="col-1" onChange={val => setName(val.target.value)} />
            </label>
            <button onClick={handleSave}>إضافة</button>
        </div>
    </>)
}

export default Product