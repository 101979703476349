import { useEffect, useState } from "react";

const Delegate = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const handleSave = () => {
        let userItems = new FormData();
        userItems.append("key", window.localStorage.getItem("osys_token"));
        userItems.append("section", "insert-delegate");
        userItems.append("name", name);
        userItems.append("phone", phone);

        fetch("https://orders.murshidkids.com/api/", {
            method: "POST",
            body: userItems,
        })
        .then((res) => res.json())
        .then((res) => {
            window.location.href = "/delegates";
        })
        //.catch((err) => console.log(err));
    }

    return(<>
        <h1>مندوب جديد</h1>
        <div className="card">
            <label className="col-3 inline-item" htmlFor="name">
                اسم المندوب
                <input id="name" type="text" placeholder="اسم المندوب" className="col-1" onChange={val => setName(val.target.value)} />
            </label>
            <label className="col-3 inline-item" htmlFor="phone">
                رقم الهاتف
                <input id="phone" type="text" placeholder="رقم الهاتف" className="col-1" onChange={val => setPhone(val.target.value)} />
            </label>

            <br />
            <button onClick={handleSave}>إضافة</button>
        </div>
    </>)
}

export default Delegate