import { useEffect, useState } from "react";

const Systembackups = () => {
  const [data, setData] = useState([]);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "load-backups");

    fetch("https://orders.murshidkids.com/api/", {
      method: "POST",
      body: form,
    })
    .then((res) => res.json())
    .then((res) => {
      setData(res);
    })
    .catch((err) => console.log(err));

  }, []);

  const handelSelectAll = () => {
    let checkboxes = document.getElementsByClassName("checkbox");
    for (let i = 0; i < checkboxes.length; i++) {
      if(toggle){
        checkboxes[i].checked = false;
      } else {
        checkboxes[i].checked = true;
      }
    }
    setToggle(!toggle);
  }

  
  const handelDelete = () => {
    let checkboxes = document.getElementsByClassName("checkbox");

    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "delete-item");
    form.append("q", 'backups');
    
    for (let i = 0; i < checkboxes.length; i++) {
      if(checkboxes[i].checked){
        form.append("item_id[]", checkboxes[i].value);
      }
    }
    
    let r = window.confirm("هل أنت متأكد سيتم الحذف نهائياً؟");

    if(r){
      fetch("https://orders.murshidkids.com/api/", {
        method: "POST",
        body: form,
      })
      .then((res) => res.json())
      .then((res) => {
        window.location.reload();
      })
      //.catch((err) => console.log(err));
    }

  }

  const restoreDb = item => {
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "restore-backup");
    form.append("q", item);
    
    fetch("https://orders.murshidkids.com/api/", {
      method: "POST",
      body: form,
    })
    .then((res) => res.json())
    .then((res) => {
      window.location.href = '/orders';
    })
    .catch((err) => console.log(err));

  }

  const createDb = () => {
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "create-backup");
    
    fetch("https://orders.murshidkids.com/api/", {
      method: "POST",
      body: form,
    })
    .then((res) => res.json())
    .then((res) => {
      window.location.reload();
    })
    .catch((err) => console.log(err));

  }

  return (<>
    <h2>قائمة النسخ الإحتياطية <a onClick={createDb} className="btn"><i className="fa fa-plus"></i> إنشاء نسخة إحتياطية</a></h2>

    <div className="inline-list col-3">
      <button className="inline-item" onClick={handelSelectAll}><i className="fa fa-list"></i> تحديد الكل</button>
      <button className="inline-item" onClick={handelDelete}><i className="fa fa-trash"></i> حذف</button>
    </div>
  
    <div className="list-view">
      {data && data.length > 0 && data.map((item, i) => (<div className="list-item inline-list" key={"item"+item.id}>
          <input type="checkbox" className="checkbox inline-item" name="checkbox[]" id={"checkbox"+item.id} value={item.name} />
        <div className="inline-item col-2" download={true}>{item.name} <br /> <small><i><i className="fa fa-calendar"></i> {item.created_at}</i></small></div>
        <button className="inline-item tag" onClick={() => restoreDb(item.name)}><i className="fa fa-redo"></i> استرجاع</button>
      </div>))}
    </div>
  </>);
}

export default Systembackups;