import { useState } from "react";

const New = () => {
    const [name, setName] = useState("");
    const [order, setOrder] = useState("");
    const [qty, setQty] = useState(1);
    const [price, setPrice] = useState(0);
    const [delivery, setDelivery] = useState(0);
    const [seller, setSeller] = useState("");
    const [amount, setAmount] = useState(0);
    const [total, setTotal] = useState(0);
    const [referrer, setReferrer] = useState("أخرى");
    const [phone, setPhone] = useState(0);
    const [mobile, setMobile] = useState(0);
    const [goverance, setGoverance] = useState("محافظة مسقط");
    const [stateName, setState] = useState("");
    const [city, setCity] = useState("");
    const [seller_name, setSellerName] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [error_msg, setErrorMsg] = useState(null);
    const [product, setProduct] = useState("");
    const [productName, setProductName] = useState("");
    const [searchType, setSearchType] = useState('products');

    const handleSave = () => {
        if(seller === "") {
            setErrorMsg("الرجاء اختيار البائع");
            return;
        }
        let orderItems = new FormData();
        orderItems.append("key", window.localStorage.getItem("osys_token"));
        orderItems.append("section", "insert-order");
        orderItems.append("name", name);
        orderItems.append("order", order);
        orderItems.append("qty", qty);
        orderItems.append("price", price);
        orderItems.append("seller", seller);
        orderItems.append("amount", delivery);
        orderItems.append("total", total);
        orderItems.append("referrer", referrer);
        orderItems.append("phone", phone);
        orderItems.append("mobile", mobile);
        orderItems.append("goverance", goverance);
        orderItems.append("state", stateName);
        orderItems.append("city", city);
        orderItems.append("product", product);
        orderItems.append("ono", Date.now());
        orderItems.append("user_id", window.localStorage.getItem("osys_user"));

        fetch("https://orders.murshidkids.com/api/", {
            method: "POST",
            body: orderItems,
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.status === 'success') {
                window.location.href = "/orders";
            } else {

            }
        })
        //.catch((err) => console.log("error: ", err));
    }

    const handelSearch = (stype, val) => {
        setSearchType(stype)
        if(stype === 'sellers'){
            setSellerName(null);
        } else {
            setProductName(null);
        }
        let form = new FormData();
        form.append("key", window.localStorage.getItem("osys_token"));
        form.append("section", "search-"+stype);
        form.append("q", val.target.value);
    
        if(val.target.value.length > 2){
          fetch("https://orders.murshidkids.com/api/", {
            method: "POST",
            body: form,
          })
          .then((res) => res.json())
          .then((res) => {
            if(res.status !== 'error'){
                setSearchData(res);
            }
          })
          //.catch((err) => console.log(err));
        }
    }

    const addData = (name, item) => {
        if(searchType === 'sellers'){
            setSellerName(name);
            setSeller(item)
        } else {
            setProductName(name);
            setProduct(item)
        }
        setSearchData([]);
    }
    
    const getTotal = () => {
        let tprice = price;
        let tdelivery = delivery;
        let ttotal = (parseFloat(tprice) + parseFloat(tdelivery)).toFixed(3)
        setTotal(ttotal)
    }

    return (<div className="card inline-list">
        <h3>طلب جديد</h3>
        <label className="col-3 inline-item" htmlFor="name">
            اسم المشتري
            <input id="name" type="text" placeholder="اسم المشتري" className="col-1" onChange={val => setName(val.target.value)} />
        </label>
        <label className="col-3 inline-item" htmlFor="order">
            الطلب
            <input id="order" type="text" placeholder="الطلب" className="col-1" onChange={val => setOrder(val.target.value)} />
        </label>

        <br />

        <label className="col-3 inline-item" htmlFor="qty">
            الكمية
            <input id="qty" type="text" placeholder="الكمية" className="col-1" onChange={val => setQty(val.target.value)} />
        </label>

        <label className="col-3 inline-item" htmlFor="price">
            السعر
            <input id="price" type="text" placeholder="السعر" className="col-1" onChange={val => setPrice(val.target.value)} />
        </label>

        <br />

        <label className="col-3 inline-item" htmlFor="amount">
            رسوم التوصيل
            <input id="amount" type="text" placeholder="رسوم التوصيل" className="col-1" onChange={val => setDelivery(val.target.value)} />
        </label>
        
        <label className="col-3 inline-item" htmlFor="total">
            اجمالي المبلغ
            <input id="total" type="text" placeholder="اجمالي المبلغ" className="col-1" onChange={val => setTotal(val.target.value)} />
        </label>

        <br />

        <label className="col-3 inline-item" htmlFor="seller">
            نوع السلعة
            <input id="product" type="text" placeholder="قم بكاتبة نوع السلعة ليتم الاكمال التلقائي..." value={productName} className="col-1" onChange={val => handelSearch('products', val)} />
            {searchType === 'products' && searchData && searchData.length > 0 && searchData.map(item => (<div onClick={() => addData(item.name, item.id)} className="list-item"><i className="fa fa-user"></i> {item.name}</div>))}
        </label>

        <label className="col-3 inline-item" htmlFor="seller">
            اسم البائع
            <input id="seller" type="text" placeholder="قم بكاتبة اسم البائع ليتم الاكمال التلقائي من قائمة البائعين..." value={seller_name} className="col-1" onChange={val => handelSearch('sellers', val)} />
            {searchType === 'sellers' && searchData && searchData.length > 0 && searchData.map(item => (<div onClick={() => addData(item.name, item.id)} className="list-item"><i className="fa fa-user"></i> {item.name}</div>))}
        </label>

        <br />

        <label className="col-3 inline-item" htmlFor="referrer">
            الطلب عن طريق
            <select name="referrer" id="referrer" onChange={val => setReferrer(val.target.value)}>
                <option value="الطلب عن طريق">الطلب عن طريق</option>
                <option value="الأصدقاء أو الأقارب">الأصدقاء أو الأقارب</option>
                <option value="السوشيال ميديا"> السوشيال ميديا</option>
                <option value="البائع مباشرة">البائع مباشرة</option>
                <option value="أخرى">أخرى</option>
            </select>
        </label>

        <br />

        <label className="col-3 inline-item" htmlFor="mobile">
            رقم هاتف الزبون 
            <input id="mobile" type="text" placeholder="رقم هاتف الزبون" className="col-1" onChange={val => setMobile(val.target.value)} />
        </label>

        <label className="col-3 inline-item" htmlFor="phone">
            رقم هاتف اخر
            <input id="phone" type="text" placeholder="رقم هاتف اخر" className="col-1" onChange={val => setPhone(val.target.value)} />
        </label>

        <br />

        <label className="col-4 inline-item" htmlFor="goverance">
            المحافظة
            <select name="goverance" id="goverance" onChange={val => setGoverance(val.target.value)}>
                <option value="محافظة مسقط">محافظة مسقط</option>
                <option value="محافظة مسندم">محافظة مسندم</option>
                <option value="محافظة البريمي">محافظة البريمي</option>
                <option value="محافظة الباطنة">محافظة الباطنة</option>
                <option value="محافظة الظاهرة ">محافظة الظاهرة </option>
                <option value="محافظة الداخلية">محافظة الداخلية</option>
                <option value="محافظة الشرقية">محافظة الشرقية</option>
                <option value="محافظة الوسطى">محافظة الوسطى</option>
                <option value="محافظة ظفار">محافظة ظفار</option>
            </select>
        </label>

        <label className="col-4 inline-item" htmlFor="state">
            الولاية
            <input id="state" type="text" placeholder="الولاية" className="col-1" onChange={val => setState(val.target.value)} />
        </label>

        <label className="col-6 inline-item" htmlFor="city">
            المنطقة
            <input id="city" type="text" placeholder="المنطقة" className="col-1" onChange={val => setCity(val.target.value)} />
        </label>

        {error_msg && <div className="error">{error_msg}</div>}
        <button onClick={handleSave}><i className="fa fa-save"></i> إضافة</button>
    </div>)}

export default New;