import { useEffect, useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Reports = () => {
  const [data, setData] = useState([]);
  const [edata, setEData] = useState([]);
  const [total, setTotal] = useState(0);
  const [goverance, setGoverance] = useState("");
  const [order_payment, setPayment] = useState("انتظار الدفع");
  const [order_payment_state, setPaymentState] = useState("");
  const [order_agent, setOrderAgent] = useState("");
  const [month, setMonth] = useState('01');
  const [year, setYear] = useState("2022");
  const [seller, setSeller] = useState('');
  const [agent_name, setAgentName] = useState("");
  const [seller_name, setSellerName] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [agentType, setAgentType] = useState("sellers");
  const [error_msg, setErrorMsg] = useState(null);
  const [product, setProduct] = useState("");
  const [productName, setProductName] = useState("");

  const handleView = () => {
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "load-reports");
    form.append("q", goverance);
    form.append("month", month);
    form.append("year", year);
    form.append("order_payment", order_payment);
    form.append("order_state", order_payment_state);
    form.append("order_agent", order_agent);
    form.append("seller", seller);

    fetch("https://orders.murshidkids.com/api/", {
      method: "POST",
      body: form,
    })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
      let temp_total = 0;
      let temp_e = [];
      res.forEach(element => {
        temp_total += parseFloat(element.total);
        temp_e.push(Object.values(element));
      });
      setTotal(temp_total)
      setData(res);
      setEData(temp_e);
    })
    //.catch((err) => console.log(err));
  }

  const loadYears = () => {
    let years = [];
    let thisYear = new Date().getFullYear();
    for (let i = 2022; i <= thisYear; i++) {
      years.push(i);
    }
    return years;
  }

  const handleExport = () => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // Desired file extesion
    const fileExtension = ".xlsx";
    let fileName = Date.now();
    //Create a new Work Sheet using the data stored in an Array of Arrays.
    const workSheet = XLSX.utils.aoa_to_sheet(edata);
    // Generate a Work Book containing the above sheet.
    const workBook = {
      Sheets: { data: workSheet, cols: [] },
      SheetNames: ["data"],
    };
    // Exporting the file with the desired name and extension.
    const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
    const fileData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(fileData, fileName + fileExtension);

  }

  
  const handelSearch = (val, searchType) => {
  
    if(val.target.value.length > 0){
      if(searchType === "sellers"){
        setSeller('');
      }else{
        setOrderAgent('');
      }
    }

    setAgentType(searchType);
    if (searchType === "sellers") {
      setSellerName(null)
    } else if(searchType === 'products'){
      setProductName(null)
    } else {
      setAgentName(null)
    }
    setSearchData([]);
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "search-"+searchType);
    form.append("q", val.target.value);

    if(val.target.value.length > 2){
      fetch("https://orders.murshidkids.com/api/", {
        method: "POST",
        body: form,
      })
      .then((res) => res.json())
      .then((res) => {
        setSearchData(res);
      })
      //.catch((err) => console.log(err));
    }
  }

  const addData = (name, item) => {
    if (agentType === "sellers") {
      setSellerName(name);
      setSeller(item)
    } else if(agentType === 'products'){
      setProductName(name)
      setProduct(item)
    } else {
      setAgentName(name);
      setOrderAgent(item)
    }
    setSearchData([]);
  }


  return (<>
    <h2>التقارير</h2>
    <div className="inline-list">      
      <h1>
        <label className="inline-item" htmlFor="referrer">
            <select name="referrer" id="referrer" onChange={val => setMonth(val.target.value)}>
              <option value="01">يناير</option>
              <option value="02">فبراير</option>
              <option value="03">مارس</option>
              <option value="04">أبريل</option>
              <option value="05">مايو</option>
              <option value="06">يونيو</option>
              <option value="07">يوليو</option>
              <option value="08">أغسطس</option>
              <option value="09">سبتمبر</option>
              <option value="14">أكتوبر</option>
              <option value="11">نوفمبر</option>
              <option value="12">ديسمبر</option>
            </select>
        </label>

        <label className="inline-item" htmlFor="referrer">
            <select name="referrer" id="referrer" onChange={val => setYear(val.target.value)}>
                {loadYears().map(item => (<option value={item}>{item}</option>))}
            </select>
        </label>

        <label className="inline-item" htmlFor="goverance">
            <select name="goverance" id="goverance" onChange={val => setGoverance(val.target.value)}>
                <option value="">جميع المحافظات</option>
                <option value="محافظة مسقط">محافظة مسقط</option>
                <option value="محافظة مسندم">محافظة مسندم</option>
                <option value="محافظة البريمي">محافظة البريمي</option>
                <option value="محافظة الباطنة">محافظة الباطنة</option>
                <option value="محافظة الظاهرة ">محافظة الظاهرة </option>
                <option value="محافظة الداخلية">محافظة الداخلية</option>
                <option value="محافظة الشرقية">محافظة الشرقية</option>
                <option value="محافظة الوسطى">محافظة الوسطى</option>
                <option value="محافظة ظفار">محافظة ظفار</option>
            </select>
        </label>

        <label className="inline-item" htmlFor="order_payment">
            <select name="order_payment" id="order_payment" value={order_payment} onChange={val => setPayment(val.target.value)}>
                {/* <option value="">حالة الدفع</option> */}
                <option value="دفع">دفع</option>
                <option value="انتظار الدفع">انتظار الدفع</option>
            </select>
        </label>

        <label className="inline-item" htmlFor="order_state">
            <select name="order_state" id="order_state" value={order_payment_state} onChange={val => setPaymentState(val.target.value)}>
                <option value="">حالة الاستلام</option>
                <option value="استلم">استلم</option>
                <option value="لم يستلم">لم يستلم</option>
            </select>
        </label>

        <br />
        
      </h1>

      <label className="inline-item" htmlFor="order_agent">
          <input type="text" name="order_agent" id="order_agent" placeholder="نوع السلعة" value={productName} onChange={val => handelSearch(val, 'products')}/>
      </label>
      
      <label className="inline-item" htmlFor="order_agent">
          <input type="text" name="order_agent" id="order_agent" placeholder="البائع" value={seller_name} onChange={val => handelSearch(val, 'sellers')}/>
      </label>

      <label className="inline-item" htmlFor="order_agent">
          <input type="text" name="order_agent" id="order_agent" placeholder="المندوب" value={agent_name} onChange={val => handelSearch(val, 'delegates')}/>
      </label>
      <br />
      {searchData && searchData.length > 0 && <div className="list-view">
        {searchData.map(item => (<div onClick={() => addData(item.name, item.id)} className="list-item"><i className="fa fa-user"></i> {item.name}</div>))}
      </div>}

      <h2 className="mrgn">
        <button className="inline-item mrgn" onClick={handleView}><i className="fa fa-eye"></i> عرض</button>
        <button className="inline-item mrgn" onClick={handleExport} disabled={(data.length === 0)}><i className="fa fa-download"></i> تصدير</button>
      </h2>

    </div>

    {total > 0 && <div className="inline-item card col-2">المجموع: {total}</div>}
    
    <div className="list-view">
      {data && data.length > 0 && data.map((item, i) => (<a className="list-item inline-list" href={"/order/"+item.id} key={"item"+item.id}>
        <span className="inline-item col-2">{item.name}</span>
        <div className="inline-item tag"><i className="fa fa-tags"></i> {!item.order_state || item.order_state === '' || item.order_payment === 'null'?'جديد':item.order_state}</div>
        <div className="inline-item tag"><i className="fa fa-tags"></i> {!item.order_payment || item.order_payment === '' || item.order_payment === 'null'?'انتظار الدفع':item.order_payment}</div>
      </a>))}
    </div>
  </>);
}

export default Reports;